@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Poppins:wght@600&family=Raleway:wght@500&family=Roboto+Slab&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Alegreya+Sans:wght@700&family=Lato:wght@400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");

/* 
font-family: 'Montserrat', sans-serif;
font-family: 'Poppins', sans-serif;
font-family: 'Raleway', sans-serif;

font-family: 'Abril Fatface', cursive;

@media screen and (min-width:768px) and (max-width:1199px){
    
  }
  
@media screen and (max-width:767px){
    
  }

*/

html,
body {
  margin: 0px;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  /* overflow: visible; */
  /* overflow-y: scroll; */
}

* {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
button:focus {
  outline: 0;
}

/* -------------------- navbar ---------------------- */
.navbar {
  background-color: rgb(33, 37, 41);
}
.navbar-brand {
  font-family: "Abril Fatface", cursive;
  letter-spacing: 2px;
  color: whitesmoke !important;
  transition: 0.2s ease-in-out;
}
.navbar-brand:hover {
  color: #548ca8 !important;
}
.navbar-brand img {
  width: 20px;
  margin-right: 8px;
}
.nav-link {
  margin-right: 15px;
  font-family: "Raleway", sans-serif;
  letter-spacing: 1px;
  color: whitesmoke !important;
  transition: 0.2s ease-in-out;
}
.nav-link:hover {
  color: #548ca8 !important;
}

/* -------------------- sec1 --------------------------- */

.banner-image {
  background-image: linear-gradient(
      to bottom,
      rgba(49, 107, 131, 0.32),
      rgba(49, 107, 131, 1)
    ),
    url("./jpgindex.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.sec1-content h1 {
  font-family: "Abril Fatface", cursive;
  letter-spacing: 2px;
  color: whitesmoke !important;
  margin-bottom: 10px;
  font-size: 80px;
}
.sec1-content h4 {
  font-family: "Lato", sans-serif;
  letter-spacing: 3px;
  color: whitesmoke !important;
  margin-bottom: 40px;
  font-size: 20px;
  font-weight: 500 !important;
}
.sec1-content h2 {
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
  color: whitesmoke !important;
  font-size: 30px;
  margin: 20px auto;
}
.sec1-content h3 {
  text-align: justify;
  font-family: "Raleway", sans-serif;
  letter-spacing: 2px;
  color: whitesmoke !important;
  font-size: 18px;
  width: 60%;
  margin: 10px auto 30px auto;
  line-height: 25px;
}
.sec1-contact {
  cursor: pointer;
  font-family: "Raleway", sans-serif;
  letter-spacing: 1.5px;
  font-weight: 700;
  border-radius: 8px;
  border: 2px whitesmoke solid;
  width: 150px;
  height: 50px;
  background-color: whitesmoke;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  transition: 0.2s ease-in-out;
  margin-bottom: 30px;
}
.sec1-contact:hover {
  background-color: transparent;
  color: whitesmoke;
}
.sec1-contact a {
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #334257;
}
.sec1-contact:hover a {
  color: whitesmoke;
}
.sec1-social a i {
  color: whitesmoke;
  font-size: 25px;
  margin: 5px;
}
.sec1-social a i:hover {
  color: #548ca8;
}

/* -------------------- sec2 --------------------------- */
.sec2 {
  margin-top: -1%;
  min-height: 500px;
  background-color: #316b83;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sec2-inner {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}
.sec2-left {
  flex-basis: 50%;
  position: relative;
}
.sec2-img1 {
  width: 300px;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.sec2-img2 {
  width: 300px;
  position: absolute;
  top: 50%;
  left: 45%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.sec2-right {
  flex-basis: 50%;
}
.sec2-right h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  color: whitesmoke;
  letter-spacing: 1.5px;
}
.sec2-right p {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: whitesmoke;
  letter-spacing: 1.5px;
  text-align: justify;
}
.sec2-right a {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  color: whitesmoke;
  letter-spacing: 1.5px;
  transition: 0.2s ease-in-out;
}

.sec2-right a:hover {
  color: #e1e8eb;
}

/* -------------------- sec3 --------------------------- */
.sec3 {
  min-height: 700px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sec3-left {
  flex-basis: 50%;
}
.sec3-left h1 {
  width: 90%;
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  color: #334257;
  letter-spacing: 1.5px;
  margin-bottom: 15px;
}
.sec3-left p {
  width: 90%;
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  color: #316b83;
  font-weight: 700;
  letter-spacing: 1.5px;
  margin-bottom: 20px;
  text-align: justify;
}
.sec3-left h2 {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #316b83;
  font-weight: 700;
  letter-spacing: 1.5px;
}
.sec3-left h2 i {
  margin-right: 12px;
}
.sec3-right {
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.sec3-box {
  cursor: pointer;
  width: 280px;
  height: 220px;
  border-radius: 8px;
  margin: 12px 7px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  color: whitesmoke;
  display: flex;
  align-items: left;
  justify-content: center;
  flex-direction: column;
  transition: 0.2s ease-in-out;
}
.sec3-box:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 15px -2px,
    rgba(0, 0, 0, 0.3) 0px 10px 12px -3px;
}
.sec3-box-top {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 8%;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
}
.sec3-box-top i {
  font-size: 25px;
  margin-right: 8px;
}
.sec3-box-bottom {
  padding-left: 8%;
  width: 90%;
  font-family: "Raleway", sans-serif;
  letter-spacing: 1.5px;
  font-size: 12px;
}
.sec3-box1 {
  background-color: #334257;
}
.sec3-box2 {
  background-color: #548ca8;
}
.sec3-box3 {
  background-color: #316b83;
}
.sec3-box4 {
  background-color: #6d8299;
}

/* -------------------- sec4 --------------------------- */
.sec4 {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #e1e8eb;
  min-height: 450px;
  max-width: 1200px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.sec4 h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  color: #334257;
  letter-spacing: 1.5px;
  margin: 15px 0;
}
.sec4 h2 {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  color: #334257;
  letter-spacing: 1.5px;
  margin: 15px 0;
}
.sec4 p {
  width: 80%;
  margin: 0 auto 15px auto;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: #316b83;
  letter-spacing: 1.5px;
  font-weight: 700;
}
.sec4-bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.sec4-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 8px;
  height: 200px;
  width: 300px;
  margin: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  transition: 0.2s ease-in-out;
}
.sec4-box:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 15px -2px,
    rgba(0, 0, 0, 0.3) 0px 10px 12px -3px;
}
.sec4-box1 {
  background-color: #548ca8;
}
.sec4-box2 {
  background-color: #316b83;
}
.sec4-box3 {
  background-color: #6d8299;
}

.sec4-box h1 {
  color: whitesmoke;
  text-align: center;
  font-size: 28px;
}
.sec4-box p {
  color: whitesmoke;
  font-size: 12px;
  width: 90%;
}

.sec4-down {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.sec4-boxx {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  border-radius: 8px;
  height: 170px;
  width: 300px;
  margin: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  transition: 0.2s ease-in-out;
}
.sec4-boxx:hover {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 15px -2px,
    rgba(0, 0, 0, 0.3) 0px 10px 12px -3px;
}
.sec4-boxx1 {
  background-color: #334257;
}
.sec4-boxx2 {
  background-color: #6d8299;
}
.sec4-boxx3 {
  background-color: #548ca8;
}
.sec4-boxx i {
  color: whitesmoke;
  text-align: center;
  font-size: 40px;
  margin-bottom: 15px;
}
.sec4-boxx p {
  color: whitesmoke;
  font-size: 12px;
  width: 90%;
}

.sec4 a {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: #334257;
  letter-spacing: 1.5px;
  margin: 10px 0 25px 0;
  transition: 0.2s ease-in-out;
}

.sec4 a:hover {
  color: #316b83;
}

/* -------------------------------- sec5 ------------------------------- */

.sec5 {
  float: left;
  margin-top: 30px;
  background: #fcfcfd;
  z-index: 1;
  width: 100%;
  overflow-x: hidden;
}

.section-title {
  float: left;
  /* position: relative; */
  width: 100%;
  margin-top: 30px;
  padding-bottom: 40px;
}

.section-title h2 {
  float: left;
  width: 100%;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  color: #334257;
  letter-spacing: 1.5px;
  font-weight: 800;
  /* position: relative; */
}
.section-title p {
  text-align: center;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  letter-spacing: 1.5px;
  font-weight: 700;
  line-height: 15px;
  max-width: 550px;
  margin: 0 auto;
}
.swiper-container {
  width: 100%;
  height: 100%;
}
.testi-item {
  transition: all 0.3s ease-in-out;
  transform: scale(0.9);
  opacity: 0.9;
}
.testimonials-text {
  padding: 75px 50px 75px;
  overflow: hidden;
  background: #f5f6fa;
  border: 1ps solid #f1f1f1;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
}
.testimonials-text-after {
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  position: absolute;
  color: #ccc;
  opacity: 0.3;
  font-size: 35px;
  transition: all 400ms linear;
  bottom: 25px;
  right: 30px;
}
.testimonials-text-before {
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  position: absolute;
  color: #ccc;
  opacity: 0.3;
  font-size: 35px;
  transition: all 400ms linear;
  top: 25px;
  left: 30px;
}
.testimonials-text .listing-rating {
  float: none;
  display: inline-block;
  margin-bottom: 12px;
}
.listing-rating i {
  color: #6d8299;
}
.testimonials-avatar h3 {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  color: #334257;
  letter-spacing: 1.5px;
  font-weight: 800;
}
.testimonials-avatar h4 {
  font-weight: 700;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  color: #548ca8;
  letter-spacing: 1.5px;
}
.testimonials-carousel .swiper-slide {
  padding: 30px 0;
}
.testi-avatar {
  position: absolute;
  left: 50%;
  top: -30px;
  width: 90px;
  height: 90px;
  margin-left: -45px;
  z-index: 20;
}
.testi-avatar img {
  width: 90px;
  height: 90px;
  float: left;
  border-radius: 100%;
  border: 6px solid #fff;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.1);
}
.swiper-slide-active .testimonials-text {
  background: #fff;
  box-shadow: 0 9px 26px rgba(58, 87, 135, 0.1);
}
.testimonials-text p {
  color: #316b83;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  line-height: 24px;
  letter-spacing: 1.5px;
  padding-bottom: 10px;
  font-weight: 500;
  text-align: justify;
}
.text-link {
  display: none;
  position: absolute;
  bottom: 0;
  padding: 15px 0;
  border-radius: 10px 10px 0 0;
  background: #f9f9f9;
  border: 1px solid #eee;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.03);
  left: 50%;
  width: 200px;
  margin-left: -100px;
}
.swiper-slide-active .testi-item {
  opacity: 1;
  transform: scale(1);
}
.tc-pagination {
  text-align: center;
  /* float: left; */
  margin-top: 10px;
  width: 100% !important;
}
.tc-pagination_wrap {
  position: absolute;
  bottom: -40px;
  left: 0;
  width: 100%;
}
.tc-pagination2 {
  float: none;
  display: inline-block;
  padding: 14px 0;
  background: #fff;
  border-radius: 30px;
  min-width: 250px;
  border-bottom: 0;
}
.tc-pagination .swiper-pagination-bullet,
.tc-pagination2.swiper-pagination-bullet {
  opacity: 1;
  background: #384f95;
  margin: 0 2px;
  width: 10px;
  height: 10px;
  transition: all 300ms ease-in-out;
}

/* ------------------------------- responsive ----------------------- */
@media screen and (min-width: 768px) and (max-width: 1199px) {
  /* .sec1-content h4{
        font-size:25px ;
    }
    .sec1-content h2{
        font-size:25px ;
    } */
  .sec1-content h3 {
    text-align: justify;
    font-family: "Raleway", sans-serif;
    letter-spacing: 2px;
    color: whitesmoke !important;
    font-size: 18px;
    width: 80%;
    margin: 10px auto 30px auto;
    line-height: 25px;
  }

  .nav-link:hover {
    color: #fff !important;
  }
  .sec2 {
    margin-top: -1%;
    min-height: 500px;
    background-color: #316b83;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sec2-inner {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
  }
  .sec2-left {
    flex-basis: 50%;
    position: relative;
    display: none;
  }
  .sec2-img1 {
    width: 300px;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .sec2-img2 {
    width: 300px;
    position: absolute;
    top: 50%;
    left: 45%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .sec2-right {
    flex-basis: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .sec2-right h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 50px;
    color: whitesmoke;
    letter-spacing: 1.5px;
  }
  .sec2-right p {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    color: whitesmoke;
    letter-spacing: 1.5px;
    text-align: justify;
  }
  .sec2-right a {
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    color: whitesmoke;
    letter-spacing: 1.5px;
    transition: 0.2s ease-in-out;
  }

  .sec2-right a:hover {
    color: #e1e8eb;
  }

  /* -------------------- sec3 --------------------------- */
  .sec3 {
    min-height: 700px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .sec3-left {
    flex-basis: 100%;
    transform: translateX(5%);
    margin: 3% 0;
  }
  .sec3-left h1 {
    width: 90%;
    font-family: "Montserrat", sans-serif;
    font-size: 35px;
    color: #334257;
    letter-spacing: 1.5px;
    margin-bottom: 15px;
  }
  .sec3-left p {
    width: 90%;
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    color: #316b83;
    font-weight: 700;
    letter-spacing: 1.5px;
    margin-bottom: 20px;
    text-align: justify;
  }
  .sec3-left h2 {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #316b83;
    font-weight: 700;
    letter-spacing: 1.5px;
  }
  .sec3-left h2 i {
    margin-right: 12px;
  }
  .sec3-right {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .sec3-box {
    cursor: pointer;
    width: 280px;
    height: 220px;
    border-radius: 8px;
    margin: 12px 7px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    color: whitesmoke;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
    transition: 0.2s ease-in-out;
  }
  .sec3-box:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 15px -2px,
      rgba(0, 0, 0, 0.3) 0px 10px 12px -3px;
  }
  .sec3-box-top {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 8%;
    font-family: "Poppins", sans-serif;
    letter-spacing: 1px;
  }
  .sec3-box-top i {
    font-size: 25px;
    margin-right: 8px;
  }
  .sec3-box-bottom {
    padding-left: 8%;
    width: 90%;
    font-family: "Raleway", sans-serif;
    letter-spacing: 1.5px;
    font-size: 12px;
  }
  .sec3-box1 {
    background-color: #334257;
  }
  .sec3-box2 {
    background-color: #548ca8;
  }
  .sec3-box3 {
    background-color: #316b83;
  }
  .sec3-box4 {
    background-color: #6d8299;
  }

  /* -------------------- sec4 --------------------------- */
  .sec4 {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #e1e8eb;
    min-height: 450px;
    max-width: 1200px;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .sec4 h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 50px;
    color: #334257;
    letter-spacing: 1.5px;
    margin: 15px 0;
  }
  .sec4 h2 {
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    color: #334257;
    letter-spacing: 1.5px;
    margin: 15px 0;
  }
  .sec4 p {
    width: 80%;
    margin: 0 auto 15px auto;
    text-align: center;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    color: #316b83;
    letter-spacing: 1.5px;
    font-weight: 700;
  }
  .sec4-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .sec4-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    border-radius: 8px;
    height: 200px;
    width: 300px;
    margin: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    transition: 0.2s ease-in-out;
  }
  .sec4-box:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 15px -2px,
      rgba(0, 0, 0, 0.3) 0px 10px 12px -3px;
  }
  .sec4-box1 {
    background-color: #548ca8;
  }
  .sec4-box2 {
    background-color: #316b83;
  }
  .sec4-box3 {
    background-color: #6d8299;
  }

  .sec4-box h1 {
    color: whitesmoke;
    text-align: center;
    font-size: 28px;
  }
  .sec4-box p {
    color: whitesmoke;
    font-size: 12px;
    width: 90%;
  }

  .sec4-down {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .sec4-boxx {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    border-radius: 8px;
    height: 170px;
    width: 300px;
    margin: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    transition: 0.2s ease-in-out;
  }
  .sec4-boxx:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 15px -2px,
      rgba(0, 0, 0, 0.3) 0px 10px 12px -3px;
  }
  .sec4-boxx1 {
    background-color: #334257;
  }
  .sec4-boxx2 {
    background-color: #6d8299;
  }
  .sec4-boxx3 {
    background-color: #548ca8;
  }
  .sec4-boxx i {
    color: whitesmoke;
    text-align: center;
    font-size: 40px;
    margin-bottom: 15px;
  }
  .sec4-boxx p {
    color: whitesmoke;
    font-size: 12px;
    width: 90%;
  }

  .sec4 a {
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #334257;
    letter-spacing: 1.5px;
    margin: 10px 0 25px 0;
    transition: 0.2s ease-in-out;
  }

  .sec4 a:hover {
    color: #316b83;
  }
}

@media screen and (max-width: 767px) {
  .sec1-content h1 {
    font-family: "Abril Fatface", cursive;
    letter-spacing: 2px;
    color: whitesmoke !important;
    margin-bottom: 10px;
    font-size: 55px;
  }
  .sec1-content h2 {
    font-family: "Poppins", sans-serif;
    letter-spacing: 2px;
    color: whitesmoke !important;
    font-size: 20px;
    margin: 20px auto;
  }
  .sec1-content h3 {
    text-align: justify;
    font-family: "Raleway", sans-serif;
    letter-spacing: 2px;
    color: whitesmoke !important;
    font-size: 14px;
    width: 90%;
    margin: 10px auto 30px auto;
    line-height: 25px;
  }

  .sec2 {
    margin-top: -1%;
    min-height: 500px;
    background-color: #316b83;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sec2-inner {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
  }
  .sec2-left {
    flex-basis: 50%;
    position: relative;
    display: none;
  }
  .sec2-img1 {
    width: 300px;
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .sec2-img2 {
    width: 300px;
    position: absolute;
    top: 50%;
    left: 45%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .sec2-right {
    flex-basis: 90%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .sec2-right h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 50px;
    color: whitesmoke;
    letter-spacing: 1.5px;
  }
  .sec2-right p {
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    color: whitesmoke;
    letter-spacing: 1.5px;
    text-align: justify;
  }
  .sec2-right a {
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    color: whitesmoke;
    letter-spacing: 1.5px;
    transition: 0.2s ease-in-out;
  }

  .sec2-right a:hover {
    color: #e1e8eb;
  }

  /* -------------------- sec3 --------------------------- */
  .sec3 {
    min-height: 700px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .sec3-left {
    flex-basis: 100%;
    transform: translateX(5%);
    margin: 4% 0;
  }
  .sec3-left h1 {
    width: 90%;
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    color: #334257;
    letter-spacing: 1.5px;
    margin: 15px 0;
  }
  .sec3-left p {
    width: 90%;
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    color: #316b83;
    font-weight: 700;
    letter-spacing: 1.5px;
    margin-bottom: 20px;
    text-align: justify;
  }
  .sec3-left h2 {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: #316b83;
    font-weight: 700;
    letter-spacing: 1.5px;
  }
  .sec3-left h2 i {
    margin-right: 12px;
  }
  .sec3-right {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .sec3-box {
    cursor: pointer;
    width: 280px;
    height: 220px;
    border-radius: 8px;
    margin: 12px 7px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    color: whitesmoke;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
    transition: 0.2s ease-in-out;
  }
  .sec3-box:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 15px -2px,
      rgba(0, 0, 0, 0.3) 0px 10px 12px -3px;
  }
  .sec3-box-top {
    display: flex;
    justify-content: left;
    align-items: center;
    padding-left: 8%;
    font-family: "Poppins", sans-serif;
    letter-spacing: 1px;
  }
  .sec3-box-top i {
    font-size: 25px;
    margin-right: 8px;
  }
  .sec3-box-bottom {
    padding-left: 8%;
    width: 90%;
    font-family: "Raleway", sans-serif;
    letter-spacing: 1.5px;
    font-size: 12px;
  }
  .sec3-box1 {
    background-color: #334257;
  }
  .sec3-box2 {
    background-color: #548ca8;
  }
  .sec3-box3 {
    background-color: #316b83;
  }
  .sec3-box4 {
    background-color: #6d8299;
  }

  /* -------------------- sec4 --------------------------- */
  .sec4 {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #e1e8eb;
    min-height: 450px;
    max-width: 1200px;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .sec4 h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 50px;
    color: #334257;
    letter-spacing: 1.5px;
    margin: 15px 0;
  }
  .sec4 h2 {
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    color: #334257;
    letter-spacing: 1.5px;
    margin: 15px 0;
  }
  .sec4 p {
    width: 80%;
    margin: 0 auto 15px auto;
    text-align: center;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    color: #316b83;
    letter-spacing: 1.5px;
    font-weight: 700;
  }
  .sec4-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .sec4-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    border-radius: 8px;
    height: 200px;
    width: 300px;
    margin: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    transition: 0.2s ease-in-out;
  }
  .sec4-box:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 15px -2px,
      rgba(0, 0, 0, 0.3) 0px 10px 12px -3px;
  }
  .sec4-box1 {
    background-color: #548ca8;
  }
  .sec4-box2 {
    background-color: #316b83;
  }
  .sec4-box3 {
    background-color: #6d8299;
  }

  .sec4-box h1 {
    color: whitesmoke;
    text-align: center;
    font-size: 28px;
  }
  .sec4-box p {
    color: whitesmoke;
    font-size: 12px;
    width: 90%;
  }

  .sec4-down {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .sec4-boxx {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    border-radius: 8px;
    height: 170px;
    width: 300px;
    margin: 15px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    transition: 0.2s ease-in-out;
  }
  .sec4-boxx:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 10px 15px -2px,
      rgba(0, 0, 0, 0.3) 0px 10px 12px -3px;
  }
  .sec4-boxx1 {
    background-color: #334257;
  }
  .sec4-boxx2 {
    background-color: #6d8299;
  }
  .sec4-boxx3 {
    background-color: #548ca8;
  }
  .sec4-boxx i {
    color: whitesmoke;
    text-align: center;
    font-size: 40px;
    margin-bottom: 15px;
  }
  .sec4-boxx p {
    color: whitesmoke;
    font-size: 12px;
    width: 90%;
  }

  .sec4 a {
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: #334257;
    letter-spacing: 1.5px;
    margin: 10px 0 25px 0;
    transition: 0.2s ease-in-out;
  }

  .sec4 a:hover {
    color: #316b83;
  }
}

/* --------------------------- footer ---------------------- */

footer {
  background-image: linear-gradient(
      to bottom,
      rgba(49, 107, 131, 0.2),
      rgba(49, 107, 131, 0.7)
    ),
    url("./3.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  margin: 25px auto 0 auto;
}
.footer-inner {
  margin: 0 auto;
  max-width: 1100px;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.footer-box {
  width: 330px;
  height: 250px;
  /* background-color: yellowgreen; */
  margin: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.footer-box img {
  height: 85px;
  margin-bottom: 15px;
}
.footer-box p {
  width: 80%;
  color: whitesmoke;
  font-size: 14px;
  font-family: "Raleway", sans-serif;
  line-height: 24px;
  letter-spacing: 1.5px;
  padding-bottom: 10px;
  font-weight: 700;
  text-align: center;
}
.footer-box h2 {
  color: whitesmoke;
  font-size: 25px;
  font-family: "Poppins", sans-serif;
  letter-spacing: 1.5px;
  padding-bottom: 10px;
  font-weight: 700;
}
.footer-box h3 {
  color: whitesmoke;
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 1.5px;
  padding-bottom: 0px;
  font-weight: 700;
}
.footer-box a {
  text-decoration: none;
  color: whitesmoke;
  font-size: 18px;
  font-family: "Raleway", sans-serif;
  line-height: 24px;
  letter-spacing: 1.5px;
  padding-bottom: 10px;
  font-weight: 700;
  transition: 0.2s ease-in-out;
  margin-bottom: 6px;
}
.footer-box a:hover {
  color: #548ca8;
}

.footer-link {
  font-size: 14px !important;
}
.down {
  text-align: center;
  padding-bottom: 15px;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  letter-spacing: 1.5px;
  color: whitesmoke;
}

/* -------------------------- about us page ----------------------- */

.abt-sec1 {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-image: linear-gradient(
      to bottom,
      rgba(49, 107, 131, 0.32),
      rgba(49, 107, 131, 1)
    ),
    url("./box4.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 400px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 90px;
  display: flex;
}
.abt-sec1-left {
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.abt-sec1-left h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 60px;
  color: whitesmoke;
  letter-spacing: 1.5px;
  font-weight: 800;
  padding-left: 8%;
}
.abt-sec1-left p {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: whitesmoke;
  letter-spacing: 1.5px;
  font-weight: 800;
  padding-left: 8%;
  text-align: justify;
}
.abt-sec1-right {
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.abt-sec1-right img {
  width: 400px;
  transform: translateX(10%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.abt-sec2 {
  max-width: 1100px;
  margin: 30px auto;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 6px 12px;
}
.abt-sec2-box {
  display: flex;
  min-height: 240px;
}
.abt-sec2-box-left {
  width: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.abt-sec2-box1-left {
  background-color: #548ca8;
}
.abt-sec2-box2-left {
  background-color: #316b83;
}
.abt-sec2-box3-left {
  background-color: #6d8299;
}
.abt-sec2-box-left p {
  width: 90%;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: whitesmoke;
  letter-spacing: 1.5px;
  font-weight: 800;
  text-align: justify;
}
.abt-sec2-box-right {
  min-height: 200px;
  width: 400px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.abt-sec2-box1-right {
  background-image: linear-gradient(
      to bottom,
      rgba(49, 107, 131, 0.32),
      rgba(49, 107, 131, 0.8)
    ),
    url("./box1.jpg");
}
.abt-sec2-box2-right {
  background-image: linear-gradient(
      to bottom,
      rgba(49, 107, 131, 0.32),
      rgba(49, 107, 131, 0.8)
    ),
    url("./box2.jpg");
}
.abt-sec2-box3-right {
  background-image: linear-gradient(
      to bottom,
      rgba(49, 107, 131, 0.32),
      rgba(49, 107, 131, 0.8)
    ),
    url("./box3.jpg");
}

.abt-sec3 {
  max-width: 1200px;
  min-height: 400px;
  margin: 30px auto;
  display: flex;
}
.abt-sec3-left {
  /* background-color: yellowgreen; */
  flex-basis: 45%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.abt-sec3-left h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  color: #334257;
  letter-spacing: 1.5px;
  font-weight: 800;
}
.abt-sec3-left p {
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  color: #6d8299;
  letter-spacing: 1.5px;
  font-weight: 800;
  width: 95%;
  text-align: justify;
}
.abt-sec3-right {
  flex-basis: 55%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.abt-sec3-box {
  background-color: #e1e8eb;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 6px 8px;
  margin: 8px;
  width: 300px;
  height: 230px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.abt-sec3-box i {
  color: #6d8299;
  font-size: 50px;
  padding-left: 7%;
}
.abt-sec3-box p {
  padding-left: 7%;
  padding-top: 10px;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #548ca8;
  letter-spacing: 1.5px;
  font-weight: 800;
  width: 95%;
  text-align: justify;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .abt-sec1 {
    max-width: 750px;
  }
  .abt-sec1-left {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .abt-sec1-left h1 {
    font-size: 60px;
  }
  .abt-sec1-left p {
    font-size: 16px;
  }
  .abt-sec1-right {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .abt-sec1-right img {
    width: 330px;
    transform: translateX(10%);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .abt-sec2 {
    max-width: 750px;
    margin: 30px auto;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 6px 12px;
  }
  .abt-sec2-box {
    display: flex;
    min-height: 280px;
  }
  .abt-sec2-box-left {
    width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .abt-sec2-box1-left {
    background-color: #548ca8;
  }
  .abt-sec2-box2-left {
    background-color: #316b83;
  }
  .abt-sec2-box3-left {
    background-color: #6d8299;
  }
  .abt-sec2-box-left p {
    width: 90%;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: whitesmoke;
    letter-spacing: 1.5px;
    font-weight: 800;
    text-align: justify;
  }
  .abt-sec2-box-right {
    min-height: 200px;
    width: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .abt-sec2-box1-right {
    background-image: linear-gradient(
        to bottom,
        rgba(49, 107, 131, 0.32),
        rgba(49, 107, 131, 0.8)
      ),
      url("./box1.jpg");
  }
  .abt-sec2-box2-right {
    background-image: linear-gradient(
        to bottom,
        rgba(49, 107, 131, 0.32),
        rgba(49, 107, 131, 0.8)
      ),
      url("./box2.jpg");
  }
  .abt-sec2-box3-right {
    background-image: linear-gradient(
        to bottom,
        rgba(49, 107, 131, 0.32),
        rgba(49, 107, 131, 0.8)
      ),
      url("./box3.jpg");
  }

  .abt-sec3 {
    max-width: 1200px;
    min-height: 400px;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
  }
  .abt-sec3-left {
    flex-basis: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .abt-sec3-left h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 35px;
    color: #334257;
    letter-spacing: 1.5px;
    font-weight: 800;
  }
  .abt-sec3-left p {
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    color: #6d8299;
    letter-spacing: 1.5px;
    font-weight: 800;
    width: 90%;
    text-align: justify;
  }
  .abt-sec3-right {
    flex-basis: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .abt-sec3-box {
    background-color: #e1e8eb;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 6px 8px;
    margin: 8px;
    width: 300px;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .abt-sec3-box i {
    color: #6d8299;
    font-size: 50px;
    padding-left: 7%;
  }
  .abt-sec3-box p {
    padding-left: 7%;
    padding-top: 10px;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    color: #548ca8;
    letter-spacing: 1.5px;
    font-weight: 800;
    width: 95%;
    text-align: justify;
  }
}

@media screen and (max-width: 767px) {
  .abt-sec1 {
    max-width: 90%;
    justify-content: center;
    flex-direction: column;
  }
  .abt-sec1-left {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .abt-sec1-left h1 {
    text-align: center;
    font-size: 45px;
    padding-left: 0%;
  }
  .abt-sec1-left p {
    margin: 0 auto;
    width: 90%;
    font-size: 12px;
    padding-left: 0%;
    margin-bottom: 4%;
  }
  .abt-sec1-right {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .abt-sec1-right img {
    width: 300px;
    transform: translateX(0%);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .abt-sec2 {
    max-width: 90%;
    margin: 30px auto;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 6px 12px;
  }
  .abt-sec2-box {
    display: flex;
    min-height: 480px;
  }
  .abt-sec2-box-left {
    width: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .abt-sec2-box1-left {
    background-color: #548ca8;
  }
  .abt-sec2-box2-left {
    background-color: #316b83;
  }
  .abt-sec2-box3-left {
    background-color: #6d8299;
  }
  .abt-sec2-box-left p {
    width: 90%;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    color: whitesmoke;
    letter-spacing: 1.5px;
    font-weight: 800;
    text-align: justify;
  }
  .abt-sec2-box-right {
    min-height: 200px;
    width: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
  .abt-sec2-box1-right {
    background-image: linear-gradient(
        to bottom,
        rgba(49, 107, 131, 0.32),
        rgba(49, 107, 131, 0.8)
      ),
      url("./box1.jpg");
  }
  .abt-sec2-box2-right {
    background-image: linear-gradient(
        to bottom,
        rgba(49, 107, 131, 0.32),
        rgba(49, 107, 131, 0.8)
      ),
      url("./box2.jpg");
  }
  .abt-sec2-box3-right {
    background-image: linear-gradient(
        to bottom,
        rgba(49, 107, 131, 0.32),
        rgba(49, 107, 131, 0.8)
      ),
      url("./box3.jpg");
  }

  .abt-sec3 {
    max-width: 1200px;
    min-height: 400px;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
  }
  .abt-sec3-left {
    flex-basis: 45%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .abt-sec3-left h1 {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 35px;
    color: #334257;
    letter-spacing: 1.5px;
    font-weight: 800;
  }
  .abt-sec3-left p {
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    color: #6d8299;
    letter-spacing: 1.5px;
    font-weight: 800;
    width: 90%;
    text-align: justify;
  }
  .abt-sec3-right {
    flex-basis: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  .abt-sec3-box {
    background-color: #e1e8eb;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 6px 8px;
    margin: 8px;
    width: 300px;
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
  }
  .abt-sec3-box i {
    color: #6d8299;
    font-size: 50px;
    padding-left: 7%;
  }
  .abt-sec3-box p {
    padding-left: 7%;
    padding-top: 10px;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    color: #548ca8;
    letter-spacing: 1.5px;
    font-weight: 800;
    width: 95%;
    text-align: justify;
  }
}

/* -------------------------- services page ----------------------- */

.ser-sec1 {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-image: linear-gradient(
      to bottom,
      rgba(49, 107, 131, 0.32),
      rgba(49, 107, 131, 1)
    ),
    url("./4.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 400px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 90px;
  display: flex;
}

.ser-sec2 {
  min-height: 400px;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.ser-sec2 h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  color: #334257;
  letter-spacing: 1.5px;
  font-weight: 800;
}
.ser-sec2 p {
  font-family: "Raleway", sans-serif;
  font-size: 18px;
  color: #6d8299;
  letter-spacing: 1.5px;
  font-weight: 800;
  width: 65%;
  text-align: justify;
}
.ser-sec2-down {
  width: 1200px;
  margin: 10px auto 0 auto;
  display: flex;
}
.ser-box {
  flex-basis: 33.3%;
  height: 200px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.ser-box h1 {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 22px;
  color: #e1e8eb;
  letter-spacing: 1.5px;
  font-weight: 800;
  margin-top: 20px;
}
.ser-box p {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  color: #e1e8eb;
  letter-spacing: 1.5px;
  font-weight: 800;
  width: 85%;
  text-align: justify;
}
.ser-box1 {
  background-color: #316b83;
}
.ser-box2 {
  background-color: #548ca8;
}
.ser-box3 {
  background-color: #6d8299;
}

.ser-sec3 {
  background-color: #e1e8eb;
  box-shadow: rgb(0 0 0 / 40%) 0px 6px 8px;
  width: 1000px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
}
.ser-sec3-inner {
  width: 90%;
  margin: 10px auto;
}
.ser-sec3-inner h1 {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  letter-spacing: 1.5px;
  font-weight: 800;
  margin-top: 20px;
  padding-bottom: 2%;
  color: #334257;
}
.ser-sec3-p {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.ser-sec3-p i {
  transform: translateY(14%);
  color: #316b83;
}
.ser-sec3-p p {
  color: #316b83;
  text-align: justify;
  padding-left: 1%;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  letter-spacing: 1.5px;
  font-weight: 800;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .ser-sec1 {
    max-width: 750px;
  }
  .ser-sec2 {
    min-height: 400px;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .ser-sec2 h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 35px;
    color: #334257;
    letter-spacing: 1.5px;
    font-weight: 800;
  }
  .ser-sec2 p {
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    color: #6d8299;
    letter-spacing: 1.5px;
    font-weight: 800;
    width: 65%;
    text-align: justify;
  }
  .ser-sec2-down {
    width: 92%;
    margin: 10px auto 0 auto;
    display: flex;
  }
  .ser-box {
    flex-basis: 33.3%;
    height: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
  .ser-box h1 {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    color: #e1e8eb;
    letter-spacing: 1.5px;
    font-weight: 800;
    margin-top: 10px;
  }
  .ser-box p {
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    color: #e1e8eb;
    letter-spacing: 1.5px;
    font-weight: 800;
    width: 85%;
    text-align: justify;
  }
  .ser-box1 {
    background-color: #316b83;
  }
  .ser-box2 {
    background-color: #548ca8;
  }
  .ser-box3 {
    background-color: #6d8299;
  }

  .ser-sec3 {
    background-color: #e1e8eb;
    box-shadow: rgb(0 0 0 / 40%) 0px 6px 8px;
    width: 90%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
  }
  .ser-sec3-inner {
    width: 90%;
    margin: 10px auto;
  }
  .ser-sec3-inner h1 {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    letter-spacing: 1.5px;
    font-weight: 800;
    margin-top: 20px;
    padding-bottom: 2%;
    color: #334257;
  }
  .ser-sec3-p {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .ser-sec3-p i {
    transform: translateY(14%);
    color: #316b83;
  }
  .ser-sec3-p p {
    color: #316b83;
    text-align: justify;
    padding-left: 1%;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
    letter-spacing: 1.5px;
    font-weight: 800;
  }
}

@media screen and (max-width: 767px) {
  .ser-sec1 {
    max-width: 350px;
    justify-content: center;
    flex-direction: column;
  }

  .ser-sec2 {
    min-height: 400px;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .ser-sec2 h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 35px;
    color: #334257;
    letter-spacing: 1.5px;
    font-weight: 800;
  }
  .ser-sec2 p {
    font-family: "Raleway", sans-serif;
    font-size: 18px;
    color: #6d8299;
    letter-spacing: 1.5px;
    font-weight: 800;
    width: 65%;
    text-align: justify;
  }
  .ser-sec2-down {
    width: 98%;
    margin: 10px auto 0 auto;
    display: flex;
  }
  .ser-box {
    flex-basis: 33.3%;
    height: 200px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }
  .ser-box h1 {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    color: #e1e8eb;
    letter-spacing: 1.5px;
    font-weight: 800;
    margin-top: 10px;
  }
  .ser-box p {
    font-family: "Raleway", sans-serif;
    font-size: 8px;
    color: #e1e8eb;
    letter-spacing: 1.5px;
    font-weight: 800;
    width: 85%;
    text-align: justify;
  }
  .ser-box1 {
    background-color: #316b83;
  }
  .ser-box2 {
    background-color: #548ca8;
  }
  .ser-box3 {
    background-color: #6d8299;
  }

  .ser-sec3 {
    background-color: #e1e8eb;
    box-shadow: rgb(0 0 0 / 40%) 0px 6px 8px;
    width: 95%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
  }
  .ser-sec3-inner {
    width: 95%;
    margin: 10px auto;
  }
  .ser-sec3-inner h1 {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 25px;
    letter-spacing: 1.5px;
    font-weight: 800;
    margin-top: 20px;
    padding-bottom: 2%;
    color: #334257;
  }
  .ser-sec3-p {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .ser-sec3-p i {
    transform: translateY(14%);
    color: #316b83;
  }
  .ser-sec3-p p {
    color: #316b83;
    text-align: justify;
    padding-left: 1%;
    font-family: "Raleway", sans-serif;
    font-size: 12px;
    letter-spacing: 1.5px;
    font-weight: 800;
  }
}

/* -------------------------- team page ----------------------- */

.te-sec1 {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-image: linear-gradient(
      to bottom,
      rgba(49, 107, 131, 0.32),
      rgba(49, 107, 131, 1)
    ),
    url("./1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 400px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 90px;
  display: flex;
}

.te-sec2 {
  max-width: 1100px;
  min-height: 400px;
  margin: 30px auto;
  display: flex;
}
.te-left {
  flex-basis: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.te-left img {
  width: 250px;
  height: 250px;
  padding: 3%;
  border: 5px solid #316b83;
  box-shadow: 0px 11px 10px -5px rgba(51, 50, 50, 0.75);
}
.te-right {
  flex-basis: 65%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  transform: translateX(2%);
}

.te-right h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  color: #334257;
  letter-spacing: 1.5px;
  font-weight: 800;
}

.te-right p {
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  color: #6d8299;
  letter-spacing: 1.5px;
  font-weight: 800;
  width: 95%;
  text-align: justify;
}
.te-right-a a {
  font-size: 25px;
  margin-right: 12px;
  color: #548ca8;
  text-decoration: none;
  transition: 0.2s ease-in-out;
}
.te-right-a a:hover {
  color: #334257;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .te-sec1 {
    max-width: 750px;
  }

  .te-sec2 {
    max-width: 750px;
    min-height: 300px;
    margin: 30px auto;
    display: flex;
  }
  .te-left {
    flex-basis: 35%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .te-left img {
    width: 230px;
    height: 230px;
    padding: 3%;
    border: 5px solid #316b83;
    box-shadow: 0px 11px 10px -5px rgba(51, 50, 50, 0.75);
  }
  .te-right {
    flex-basis: 65%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    transform: translateX(2%);
  }

  .te-right h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    color: #334257;
    letter-spacing: 1.5px;
    font-weight: 800;
  }

  .te-right p {
    font-family: "Raleway", sans-serif;
    font-size: 9px;
    color: #6d8299;
    letter-spacing: 1.5px;
    font-weight: 800;
    width: 95%;
    text-align: justify;
  }
  .te-right-a a {
    font-size: 25px;
    margin-right: 12px;
    color: #548ca8;
    text-decoration: none;
    transition: 0.2s ease-in-out;
  }
  .te-right-a a:hover {
    color: #334257;
  }
}

@media screen and (max-width: 767px) {
  .te-sec1 {
    max-width: 350px;
    justify-content: center;
    flex-direction: column;
  }

  .te-sec2 {
    max-width: 750px;
    min-height: 300px;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
  }
  .te-left {
    flex-basis: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .te-left img {
    width: 250px;
    height: 250px;
    padding: 3%;
    border: 5px solid #316b83;
    box-shadow: 0px 11px 10px -5px rgba(51, 50, 50, 0.75);
    margin-bottom: 15px;
  }
  .te-right {
    flex-basis: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    transform: translateX(0%);
  }

  .te-right h1 {
    width: 100%;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    color: #334257;
    letter-spacing: 1.5px;
    font-weight: 800;
  }

  .te-right p {
    font-family: "Raleway", sans-serif;
    font-size: 10px;
    color: #6d8299;
    letter-spacing: 1.5px;
    font-weight: 800;
    width: 90%;
    margin: 0 auto;
    text-align: justify;
  }
  .te-right-a {
    width: 90%;
    margin: 0 auto;
  }
  .te-right-a a {
    font-size: 25px;
    margin-right: 12px;
    color: #548ca8;
    text-decoration: none;
    transition: 0.2s ease-in-out;
  }
  .te-right-a a:hover {
    color: #334257;
  }
}

/* -------------------------- contact us page ----------------------- */

.cu-sec1 {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-image: linear-gradient(
      to bottom,
      rgba(49, 107, 131, 0.32),
      rgba(49, 107, 131, 1)
    ),
    url("./12.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 400px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 90px;
  display: flex;
}
.cu-sec2 {
  min-height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}
.contact-form {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 6px 8px;
  width: 80vw;
  display: flex;
  justify-content: space-between;
  background: #e1e8eb;
  margin: 30px 0;
}
.contact-form > * {
  width: 50%;
}
.contact-form .first-container {
  background-image: linear-gradient(
      to bottom,
      rgba(49, 107, 131, 0.32),
      rgba(49, 107, 131, 1)
    ),
    url("./13.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-form .first-container .info-container div {
  margin: 24px 0;
}
.contact-form .first-container .info-container div h3 {
  color: whitesmoke;
  font-size: 22px;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  line-height: 1.2;
  padding-bottom: 10px;
}
.contact-form .first-container .info-container div p {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  line-height: 1.6;
  color: whitesmoke;
}
.contact-form .first-container .info-container div:first-of-type p {
  max-width: 260px;
}
.contact-form .second-container {
  padding: 30px;
}
.contact-form .second-container form {
  display: flex;
  flex-direction: column;
}
.contact-form .second-container h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 30px;
  font-weight: 400;
  color: #334257;
  line-height: 1.2;
  text-align: center;
  margin-bottom: 20px;
}
.contact-form .second-container form .form-group {
  margin-bottom: 10px;
}
.contact-form .second-container form .form-group * {
  min-height: 55px;
  border: 1px solid #e6e6e6;
  padding: 0 20px;
}
.contact-form .second-container form .form-group label {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #e6e6e6;
  font-size: 16px;
  color: #6d8299;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin-top: -1px;
}
.contact-form .second-container form .form-group:first-of-type input {
  width: 50.1%;
  margin-right: -5px;
  font-family: "Raleway", sans-serif;
}
.contact-form .second-container form .form-group input {
  font-family: "Raleway", sans-serif;
  width: 100%;
  font-size: 15px;
  margin-top: -2px;
}
.contact-form .second-container form .form-group input::placeholder,
.contact-form .second-container form .form-group .textarea::placeholder {
  color: #999;
}
.contact-form .second-container form .form-group .textarea {
  width: 100%;
  min-height: 80px;
  resize: none;
  padding: 10px 20px;
  margin-top: -1px;
}
.contact-form .second-container form button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 50px;
  background: #316b83;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  text-transform: uppercase;
  border: 0;
  position: relative;
  left: calc(50% - 100px);
  cursor: pointer;
}
.contact-form .second-container form button:hover {
  background: #6d8299;
}
@media screen and (max-width: 800px) {
  .contact-form {
    width: 90vw;
  }
}
@media screen and (max-width: 700px) {
  .contact-form {
    flex-direction: column-reverse;
  }
  .contact-form > * {
    width: 100%;
  }
  .contact-form .first-container {
    padding: 40px 0;
  }
}

.cu-map {
  text-align: center;
  margin: 3% auto;
}
.cu-map iframe {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 6px 8px;
  width: 1200px;
  height: 500px;
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .cu-map iframe {
    width: 750px;
    height: 500px;
  }
}

@media screen and (max-width: 767px) {
  .cu-map iframe {
    width: 350px;
    height: 500px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .cu-sec1 {
    max-width: 750px;
  }
}

@media screen and (max-width: 767px) {
  .cu-sec1 {
    max-width: 350px;
    justify-content: center;
    flex-direction: column;
  }
}

/* ------------------- disclaimer ------------------- */

.dis {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 30px;
}
.dis h2 {
  font-family: "Lato", sans-serif;
  letter-spacing: 3px;
  color: #334257;
  margin-bottom: 10px;
  font-size: 40px;
  font-weight: 700 !important;
}

.dis p {
  text-align: justify;
  font-family: "Raleway", sans-serif;
  letter-spacing: 2px;
  color: #316b83 !important;
  font-size: 18px;
  width: 90%;
  margin: 10px auto 30px auto;
  line-height: 25px;
  font-weight: 700;
}

.dis button {
  text-decoration: none;
  color: whitesmoke;
  background-color: #316b83;
  border: 4px solid #316b83;
  font-family: "Poppins", sans-serif;
  padding: 8px 18px;
  transition: 0.2s ease-in-out;
}
.dis button:hover {
  color: #316b83;
  background-color: transparent;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .dis p {
    width: 80%;
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 767px) {
  .dis p {
    width: 90%;
    font-size: 12px;
    line-height: 18px;
  }
}
